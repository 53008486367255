import {ICartItemGql, IPaymentOptionType} from '../../types/app.types';
import {UrlModel} from './Url.model';
import {CatalogReferenceModel} from './CatalogReference.model';
import {ImageModel} from './Image.model';
// eslint-disable-next-line import/no-deprecated
import {RenderingConfigModel} from './RenderingConfig.model';
import {PriceModel} from './Price.model';
import {DiscountRuleModel} from './DiscountRule.model';
import {DescriptionLineModelModel} from './DescriptionLineModel.model';
// eslint-disable-next-line import/no-deprecated
import {guidToInt} from '@wix/wixstores-client-storefront-sdk';
import {DescriptionLine, LineItem} from '@wix/ecom_current-cart';

export class LineItemModel {
  public id: number;
  public sku: string;
  public quantity: number;
  public quantityAvailable: number;
  public itemType: string;
  public url: UrlModel;
  public productName: string;
  public catalogReference: CatalogReferenceModel;
  public price: PriceModel;
  public fullPrice: PriceModel;
  public priceBeforeDiscounts: PriceModel;
  public lineItemPrice: PriceModel;
  public image: ImageModel;
  public descriptionLines: DescriptionLineModelModel[];
  /**
   * @deprecated
   */
  // eslint-disable-next-line import/no-deprecated
  public renderingConfig: RenderingConfigModel;
  public paymentOption: IPaymentOptionType;
  public savePaymentMethod: boolean;
  public depositAmount: PriceModel;
  /**
   * @deprecated
   */
  // eslint-disable-next-line import/no-deprecated
  public selectedMembershipName: string;
  public discountRules: DiscountRuleModel[];

  constructor(params: LineItemModel) {
    this.id = params.id;
    this.sku = params.sku;
    this.quantity = params.quantity;
    this.quantityAvailable = params.quantityAvailable;
    this.itemType = params.itemType;
    this.url = params.url;
    this.productName = params.productName;
    this.catalogReference = params.catalogReference;
    this.price = params.price;
    this.fullPrice = params.fullPrice;
    this.priceBeforeDiscounts = params.priceBeforeDiscounts;
    this.lineItemPrice = params.lineItemPrice;
    this.image = params.image;
    this.descriptionLines = params.descriptionLines;
    // eslint-disable-next-line import/no-deprecated
    this.renderingConfig = params.renderingConfig;
    this.paymentOption = params.paymentOption;
    this.savePaymentMethod = params.savePaymentMethod;
    this.depositAmount = params.depositAmount;
    this.selectedMembershipName = params.selectedMembershipName;
    this.discountRules = params.discountRules;
  }

  public static fromGQL(lineItem: ICartItemGql) {
    return new LineItemModel({
      id: lineItem.cartItemId,
      sku: lineItem.sku,
      quantity: lineItem.quantity,
      quantityAvailable: lineItem?.inventoryQuantity ?? undefined,
      itemType: lineItem.product?.productType ?? /* istanbul ignore next */ undefined,
      url: UrlModel.fromGQL(lineItem.product?.urlPart, lineItem.product?.pageUrl),
      productName: lineItem.product?.name ?? /* istanbul ignore next */ undefined,
      catalogReference: CatalogReferenceModel.fromGQL(lineItem.product?.id, lineItem.catalogAppId),
      price: PriceModel.fromGQL({
        convertedAmount: lineItem?.convertedPrices?.price,
        formattedConvertedAmount: lineItem?.convertedPrices?.formattedPrice,
      }),
      fullPrice: PriceModel.fromGQL({
        formattedConvertedAmount: lineItem?.convertedPrices?.formattedComparePrice,
      }),
      priceBeforeDiscounts: PriceModel.fromGQL({
        formattedConvertedAmount: lineItem?.convertedPrices?.formattedPriceBeforeDiscount,
      }),
      lineItemPrice: PriceModel.fromGQL({
        convertedAmount: lineItem?.convertedPrices?.totalPrice,
        formattedConvertedAmount: lineItem?.convertedPrices?.formattedTotalPrice,
      }),
      image: lineItem.product?.media?.[0] ? ImageModel.fromGQL(lineItem?.product?.media?.[0]) : undefined,
      descriptionLines: [
        ...(lineItem.optionsSelectionsValues ?? /* istanbul ignore next */ []),
        ...(lineItem.freeText ?? /* istanbul ignore next */ []),
      ].map((descriptionLine) => DescriptionLineModelModel.fromGQL(descriptionLine.title, descriptionLine.value)),
      // eslint-disable-next-line import/no-deprecated
      renderingConfig: lineItem?.renderingConfig ? RenderingConfigModel.fromGQL(lineItem.renderingConfig) : undefined,
      paymentOption: lineItem.paymentType ?? /* istanbul ignore next */ undefined,
      savePaymentMethod: lineItem.savePaymentMethod ?? /* istanbul ignore next */ undefined,
      depositAmount: PriceModel.fromGQL({
        convertedAmount: lineItem?.convertedPrices?.depositAmount,
        formattedConvertedAmount: lineItem?.convertedPrices?.formattedDepositAmount,
      }),
      selectedMembershipName: lineItem?.selectedMembership?.name?.original ?? /* istanbul ignore next */ undefined,
      discountRules: (lineItem?.discountRules ?? []).map((discountRule) => DiscountRuleModel.fromGQL(discountRule)),
    });
  }

  public static fromSDK(lineItem: LineItem) {
    return new LineItemModel({
      // eslint-disable-next-line import/no-deprecated
      id: guidToInt(lineItem._id),
      sku: lineItem.physicalProperties?.sku,
      quantity: lineItem.quantity,
      quantityAvailable: lineItem.availability?.quantityAvailable,
      itemType:
        lineItem.itemType?.preset?.toLowerCase() ?? /* istanbul ignore next */ lineItem.itemType?.custom?.toLowerCase(),
      url: UrlModel.fromSDK(lineItem.url, lineItem.url),
      productName: lineItem.productName.translated,
      catalogReference: CatalogReferenceModel.fromSDK(lineItem.catalogReference),
      price: PriceModel.fromSDK(lineItem?.price),
      fullPrice: PriceModel.fromSDK(lineItem?.fullPrice),
      priceBeforeDiscounts: PriceModel.fromSDK(lineItem?.priceBeforeDiscounts),
      lineItemPrice: PriceModel.fromGQL({
        convertedAmount: undefined,
        formattedConvertedAmount: undefined,
      }),
      image: ImageModel.fromSDK(lineItem.image),
      descriptionLines: (lineItem?.descriptionLines ?? /* istanbul ignore next */ []).map(
        (descriptionLine: DescriptionLine) => DescriptionLineModelModel.fromSDK(descriptionLine)
      ),
      paymentOption: lineItem.paymentOption,
      savePaymentMethod: lineItem.savePaymentMethod,
      depositAmount: PriceModel.fromSDK(lineItem?.depositAmount),
      discountRules: undefined,
      selectedMembershipName: undefined,
      renderingConfig: undefined,
    });
  }
}
