import {CartType, ProductType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import type {
  CartActions,
  ICurrentCartService,
  SelectedShippingOption,
  SiteStore,
  ICart as ICartFromCartApi,
} from '@wix/wixstores-client-storefront-sdk';
import {
  CheckoutApi,
  // eslint-disable-next-line import/no-deprecated
  intToGuid,
} from '@wix/wixstores-client-storefront-sdk';
import {CreateCheckoutExceptions} from '../../common/constants';
import {ICartControllerApi} from '../../types/app.types';
import {BIService} from './BIService';
import {StyleSettingsService} from './StyleSettingsService';
import {SPECS} from '../specs';
import {CartModel} from '../models/Cart.model';
import {LineItemModel} from '../models/LineItem.model';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {ViolationSeverity} from '../models/Violation.model';
import {Cart} from '@wix/ecom_current-cart';

export type CouponError = {
  code: string;
  message: string;
};

export class CartService {
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutApi: CheckoutApi;
  private readonly cartActions: CartActions;
  private readonly currentCartService: ICurrentCartService;
  public couponError: CouponError = null;
  public cartModel: CartModel;
  public estimatedTotals: EstimatedTotalsModel;
  public checkoutId: string;
  public origin: string;
  public hasError: boolean;

  constructor({
    siteStore,
    biService,
    styleSettingsService,
    currentCartService,
    origin,
  }: {
    controllerApi: ICartControllerApi;
    siteStore: SiteStore;
    biService: BIService;
    styleSettingsService: StyleSettingsService;
    currentCartService: ICurrentCartService;
    origin: string;
  }) {
    this.siteStore = siteStore;
    this.biService = biService;
    this.styleSettingsService = styleSettingsService;
    this.currentCartService = currentCartService;
    this.origin = origin;
    this.checkoutApi = new CheckoutApi({siteStore, origin});
    this.cartActions = this.currentCartService.cartActions;
  }

  public async fetchCart(): Promise<void> {
    await this.setCartFromCurrentCartService();
  }

  public async setCartFromCurrentCartService() {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;

    if (
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      (shouldShowShipping || shouldShowTax)
    ) {
      const cart = await this.currentCartService.getCurrentCart();
      this.setCartModel(cart);
      this.setGQLEstimatedTotalModel(await this.getCarGql());
    } else {
      const cartGql = await this.getCarGql();
      this.setCartGQLModel(cartGql);
      this.setGQLEstimatedTotalModel(cartGql);
    }
  }

  private getCarGql() {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;
    return this.currentCartService.getCurrentCartGQL({
      withShipping: shouldShowShipping,
      withTax: shouldShowTax,
    });
  }

  public get cartType(): CartType {
    const hasDigital = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.DIGITAL);
    const hasPhysical = this.hasShippableItems;
    const hasService = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.SERVICE);
    const hasGiftCard = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.GIFT_CARD);
    const hasMultiVerticalItems = (hasDigital || hasPhysical) && (hasService || hasGiftCard);

    if (hasMultiVerticalItems) {
      return CartType.MIXED_VERTICALS;
    }

    /* istanbul ignore next */
    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else if (hasPhysical) {
      return CartType.PHYSICAL;
    } else if (hasService) {
      return CartType.SERVICE;
    } else if (hasGiftCard) {
      return CartType.GIFT_CARD;
    } else {
      return CartType.UNRECOGNISED;
    }
  }

  public get isNonShippableCart(): boolean {
    return !this.hasShippableItems;
  }

  public get hasShippableItems(): boolean {
    return this.cartModel?.lineItems.some(
      (lineItem) => !lineItem.itemType || lineItem.itemType === ProductType.PHYSICAL
    );
  }

  public get isZeroCart(): boolean {
    return this.estimatedTotals?.priceSummary?.total?.convertedAmount
      ? this.estimatedTotals?.priceSummary?.total?.convertedAmount === 0
      : this.cartModel?.subtotal.convertedAmount === 0;
  }

  public get isEmpty(): boolean {
    return !this.cartModel?.lineItems.length;
  }

  public get areAllItemsInStock(): boolean {
    return (
      this.cartModel?.lineItems &&
      this.cartModel.lineItems.every(
        (lineItem) => lineItem.quantityAvailable === undefined || lineItem.quantityAvailable > 0
      )
    );
  }

  public get isFullAddressRequired() {
    return this.estimatedTotals.shippingInfo?.status === ShippingRuleStatus.FullAddressRequired;
  }

  public get itemsCount(): number {
    return this.cartModel.lineItems.reduce((count, lineItem) => count + lineItem.quantity, 0);
  }

  public get hasErrorViolations(): boolean {
    return this.estimatedTotals?.violations?.some((violation) => violation.severity === ViolationSeverity.error);
  }

  public createCheckout(): Promise<string | {error: string} | undefined> {
    return this.checkoutApi
      .createCheckout(this.cartModel.id)
      .then((id) => (this.checkoutId = id))
      .catch((error) => {
        console.error(error);

        return JSON.stringify(error)
          .toLowerCase()
          .includes(CreateCheckoutExceptions.siteMustAcceptPayments.toLowerCase())
          ? {error: CreateCheckoutExceptions.siteMustAcceptPayments}
          : undefined;
      });
  }

  public readonly updateItemQuantity = async (
    cartItemId: number,
    quantity: number,
    productId: string
  ): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      // eslint-disable-next-line import/no-deprecated
      return this.currentCartService.updateLineItemQuantity({lineItemId: intToGuid(cartItemId), quantity});
    }
    return this.cartActions.updateLineItemQuantityInCart(
      {
        cartId: this.cartModel.id,
        cartItemId,
        quantity,
        productId,
        itemsCount: this.cartModel.lineItems.length,
        cartType: this.cartType,
      },
      {origin: this.origin}
    );
  };

  public readonly updateBuyerNote = async (content: string) => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      await this.currentCartService.updateBuyerNote({buyerNote: content});
    } else {
      await this.cartActions.updateBuyerNote(this.cartModel.id, content);
    }
    this.biService.updateBuyerNote(this.cartModel, !!content);
  };

  public readonly removeItemFromCart = async (lineItem: LineItemModel): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      // eslint-disable-next-line import/no-deprecated
      return this.currentCartService.removeLineItem({lineItemId: intToGuid(lineItem.id)});
    }
    return this.cartActions.removeItemFromCart(
      {
        cartId: this.cartModel.id,
        cartItemId: lineItem.id,
        price: lineItem.price.convertedAmount,
        productId: lineItem.catalogReference.catalogItemId,
        productName: lineItem.productName,
        productType: lineItem.itemType,
        quantity: lineItem.quantity,
        sku: lineItem.sku,
        currency: this.cartModel.currencyCode,
        catalogAppId: lineItem.catalogReference.appId,
      },
      {origin: this.origin}
    );
  };

  public readonly setDestinationForEstimation = async (
    {
      country,
      subdivision,
      zipCode,
    }: {
      country: string;
      subdivision?: string;
      zipCode?: string;
    },
    cartId: string
  ): Promise<void> => {
    return this.cartActions.setDestinationForEstimation({destination: {country, subdivision, zipCode}}, cartId);
  };

  public readonly setShippingOption = async (
    cartId: string,
    selectedShippingOption: SelectedShippingOption
  ): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      return this.currentCartService.selectShippingOption({selectedShippingOption});
    }
    return this.cartActions.setShippingOption(cartId, selectedShippingOption);
  };

  public get isMemberLoggedIn(): boolean {
    return !!this.siteStore.usersApi.currentUser && !!this.siteStore.usersApi.currentUser.id;
  }

  public readonly clearCouponError = (): void => {
    this.couponError = null;
  };

  public readonly applyCoupon = async (couponCode: string): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ErrorMessage) && !couponCode) {
      const errorCode = 'ERROR_EMPTY_INPUT';
      this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
      this.couponError = {
        code: errorCode,
        message: '',
      };
      throw new Error(errorCode);
    }

    const userIdentifier = this.siteStore.usersApi.currentUser.loggedIn
      ? await this.siteStore.usersApi.currentUser.getEmail()
      : undefined;

    await this.cartActions
      .applyCouponToCart({cartId: this.cartModel.id, couponCode, userIdentifier, isMember: this.isMemberLoggedIn})
      .catch((e) => {
        /* istanbul ignore else */
        if (e.success === false) {
          const errorCode = e.errors[0].code;
          this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
          this.couponError = {
            code: errorCode,
            message: e.errors[0].message,
          };
        }
        throw e;
      });
  };

  public readonly removeCoupon = (): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      return this.currentCartService.removeCoupon();
    }
    return this.cartActions.removeCouponFromCart({
      cartId: this.cartModel.id,
      couponId: this.cartModel.appliedCoupon.id,
      couponCode: this.cartModel.appliedCoupon.code,
    });
  };

  public readonly setHasErrorState = (value: boolean) => (this.hasError = value);

  private readonly setCartGQLModel = (cart: ICartFromCartApi) => (this.cartModel = CartModel.fromGQL(cart));
  private readonly setCartModel = (cart: Cart) => (this.cartModel = CartModel.fromSDK(cart));
  private readonly setGQLEstimatedTotalModel = (cart: ICartFromCartApi) =>
    (this.estimatedTotals = EstimatedTotalsModel.fromGQL(cart));
}
